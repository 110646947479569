import {
  Box,
  Button,
  Container,
  LinearProgress,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import theme from "./theme";

import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

import { useEffect, useState } from "react";
import axios from "axios";

import LaunchIcon from "@mui/icons-material/Launch";

import { ReactComponent as WebSvg } from "./svg/web.svg";
import { ReactComponent as InstagramSvg } from "./svg/instagram.svg";
import Image from "mui-image";

const searchParams = new URLSearchParams(document.location.search);

const utm_source = searchParams.get("utm_source") ?? "";
const utm_campaign = searchParams.get("utm_campaign") ?? "";

const API_URL = "https://api.lombard-cabinet.kz";

const params = `utm_source=${utm_source}&utm_campaign=${utm_campaign}`;

function App() {
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState<
    { url: string; name: string; type: "instagram" | "site"; params?: any }[]
  >([]);

  useEffect(() => {
    async function getLinks() {
      try {
        const { data } = await axios.get("links.json");
        setLinks(data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    getLinks();
  }, []);

  const openUrl = (url: string, add_params = "") => {
    window.open(
      `${API_URL}/utm-handler/save?redirect=${encodeURIComponent(
        `${url}?${params}${add_params}`
      )}&${params}`,
      "_blank"
    );
  };

  return loading ? (
    <LinearProgress />
  ) : (
    <ThemeProvider theme={theme}>
      <AppHeader />
      <Container>
        {links.map((x, i) => (
          <Box
            key={String(i)}
            bgcolor={"#F7F7F8"}
            onClick={() => {
              openUrl(x.url, x.params);
            }}
            sx={{ cursor: "pointer" }}
            display="flex"
            flexDirection="row"
            alignItems="center"
            py={1}
            px={2}
            mb={2}
            borderRadius={3}
          >
            <Box>{x.type === "instagram" ? <InstagramSvg /> : <WebSvg />}</Box>
            <Typography flex={1} px={2} py={1}>
              {x.name}
            </Typography>
            <Box>
              <LaunchIcon />
            </Box>
          </Box>
        ))}
        <Box my={3}>
          <Typography variant="h5" fontWeight={"bold"}>
            Скачайте наше приложение
          </Typography>
          <Stack
            mt={2}
            display="flex"
            flexDirection={"row"}
            gap={2}
            alignItems="center"

            // justifyContent="space-between"
            // justifyContent={"space-evenly"}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() =>
                openUrl(
                  "https://apps.apple.com/kz/app/%D0%B1%D0%B5%D0%BB%D1%8B%D0%B9/id1601099880"
                )
              }
            >
              <Image width={"172px"} src="/images/appstore.png" />
            </Box>

            <Box
              sx={{ cursor: "pointer" }}
              onClick={() =>
                openUrl(
                  "https://play.google.com/store/apps/details",
                  "&id=kz.white"
                )
              }
            >
              <Image width={"172px"} src="/images/googleplay.png" />
            </Box>
          </Stack>
        </Box>
        <Box mb={3}>
          <Typography variant="h5" fontWeight={"bold"}>
            Отзывы
          </Typography>
          <Typography mb={2}>
            Ваши отзывы бесценны для нас. Пройдите по ссылке и опубликуйте их!
          </Typography>
          <Button size="small" variant="contained">
            Перейти в отзывы
          </Button>
        </Box>
      </Container>
      <AppFooter />
    </ThemeProvider>
  );
}

export default App;
