import { createTheme } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    light: Palette["primary"];
    dark: Palette["primary"];
  }
  interface PaletteOptions {
    light: PaletteOptions["primary"];
    dark: PaletteOptions["primary"];
  }
}

const themePallete = createTheme({
  typography: {
    fontFamily: "Roboto",
  },
  palette: {
    primary: {
      main: "#FFD33F",
    },

    divider: "#EFEFEF",
    light: {
      main: "#ffffff",
    },
    dark: {
      main: "#000000",
    },
    background: {},
  },
});
const theme = createTheme({
  ...themePallete,

  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInput: {
      defaultProps: {
        style: {
          border: `1px solid ${themePallete.palette.divider}`,
          padding: "5px",
          borderRadius: "8px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: `1px solid ${themePallete.palette.divider}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          borderRadius: "90px",
          textTransform: "none",
          boxShadow: "none",

          padding: themePallete.breakpoints.down("md")
            ? "16px 16px"
            : "16px 36px",

          fontWeight: "600",
          fontSize: "16px",
          minHeight: "51px",
        },
      },
      variants: [
        {
          props: {
            variant: "contained",
          },
          style: {
            ":hover": {
              boxShadow:
                "0px 14px 24px -7px rgba(231, 175, 32, 0.3) !important;",
              backgroundColor: themePallete.palette.primary.main,
            },
          },
        },
        {
          props: {
            variant: "outlined",
            color: "light",
          },
          style: {
            border: `1px solid ${themePallete.palette.light.main}`,
            color: themePallete.palette.light.main,
            ":hover": {
              border: `1px solid ${themePallete.palette.primary.main}`,
              color: themePallete.palette.primary.main,
            },
          },
        },
        {
          props: {
            variant: "outlined",
            color: "dark",
          },
          style: {
            border: `1px solid ${themePallete.palette.dark.main}`,
            color: themePallete.palette.dark.main,
            ":hover": {
              border: `1px solid ${themePallete.palette.primary.main}`,
              color: themePallete.palette.primary.main,
            },
          },
        },
      ],
    },
  },
});

export default theme;
