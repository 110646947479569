import { Container, Divider, Grid, Link, Typography } from "@mui/material";
import React from "react";

const CURRENT_YEAR = new Date().getFullYear();

export default function AppFooter() {
  return (
    <>
      <Container>
        <Grid
          container
          py={2.5}
          justifyContent={{ md: "space-between", xs: "start" }}
          rowGap={2}
        >
          <Grid item>
            <Typography fontSize={14} fontWeight="600">
              ©{CURRENT_YEAR} Все права защищены
            </Typography>
            <Typography mt={1} color="gray" fontSize={12}>
              БЕЛЫЙ Казахстан, Алматы.
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              display="block"
              component={Link}
              href="https://lombard-b.kz/privacy-policy/"
              fontSize={14}
              fontWeight="600"
              underline="hover"
              color="black"
              textAlign={{ xs: "start", md: "right" }}
            >
              Политика конфиденциальности
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container sx={{ py: 2 }}>
        <Typography color="gray" fontSize={12}>
          Лицензия: KZ65VGY00000658 от 21.04.2020
          <br />
          Управление региональных представителей в г. Алматы Агентства
          Республики Казахстан по регулированию и развитию финансовых рынков
          уведомляет о включении ТОО "Ломбард белый LLP" в реестр
          микрофинансовых организаций за №02Л20.010 от 21.04.2020 г.
        </Typography>
      </Container>
    </>
  );
}
