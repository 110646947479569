import {
  AppBar,
  Container,
  Grid,
  styled,
  Theme,
  useMediaQuery,
} from "@mui/material";

import { ReactComponent as LogoSvg } from "../svg/logo.svg";

const Offset = styled("div")(({ theme }: any) => theme.mixins.toolbar);

export default function AppHeader() {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  return (
    <>
      <AppBar position="fixed">
        <Container sx={{ px: 2, py: 1 }}>
          <Grid
            columnSpacing={{ xs: 0, md: 2 }}
            container
            order={{ xs: 0 }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs>
              <LogoSvg width={isMd ? 180 : 111} />
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Offset sx={{ mt: 4.8 }} />
    </>
  );
}
